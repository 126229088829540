<template>
	<v-container>
		<v-layout row wrap>
			<v-flex xs12>
				<v-card>
					<v-toolbar flat color="#0f2e8c">
						<h3 class="white--text">QUẢN LÝ ĐƠN HÀNG ĐẤU GIÁ YAHOO</h3>
						<v-spacer />
						<v-btn
							class="mx-2"
							color="blue"
							dark
							@click="doFetch()"
						>
							Tải lại dữ liệu
						</v-btn>
					</v-toolbar>
					<v-card-title>
						<v-row>
							<v-col
								cols="auto"
							>
								<v-select
									v-model="Filter.Status"
									:items="StatusList"
									item-value="code"
									item-text="name"
									label="Trạng thái"
								/>
							</v-col>
							<v-spacer></v-spacer>
							<v-col
								cols="12"
								md="6"
								lg="4"
							>
								<v-text-field
									v-model="Search"
									append-icon="mdi-magnify"
									label="Tìm kiếm"
									single-line
									hide-details
									@change="doSearch()"
									v-on:keyup.enter="onSearch()"
								/>
							</v-col>
						</v-row>
					</v-card-title>
					<v-data-table
						:options.sync="options"
						:server-items-length="totalItems"
						:loading="Loader.Data"
						:headers="headers"
						:items="items"
						loading-text="Đang tải dữ liệu..."
						:footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
						:items-per-page="20"
						item-key="_id"
					>
						<template v-slot:[`item.Description`]="{ item }">
							<v-textarea
								v-model="item.Description"
								readonly
								:rows="3"
							/>
						</template>
						<template v-slot:[`item.Image`]="{ item }">
							<img :src="item.Image" class="img-responsive" style="max-width: 100px; max-height: 100px;" />
						</template>
						<template v-slot:[`item.Name`]="{ item }">
							<a
								:href="item.Url"
								target="_blank"
								class="mx-2 blue--text text-decoration-none"
							>
								{{item.Name}}
							</a>
						</template>
						<template v-slot:[`item.Price`]="{ item }">
							<div class="mx-2 text-center pink--text">
								Giá đấu hiện tại
							</div>
							<div class="mx-2 text-center blue--text">
								{{DoiTien(item.Price,false)}}
							</div>
							<div class="mx-2 text-center blue--text">
								{{DoiTien(item.PriceVN)}}
							</div>
						</template>
						<template v-slot:[`item.End`]="{ item }">
							<template v-if="item.End">
								<div class="mx-2 text-center pink--text">
									Đấu giá đã kết thúc
								</div>
							</template>
							<template v-else>
								<Countdown :end="convertTime(item.Countdown)" />
							</template>
						</template>
						<template v-slot:[`item.Bid`]="{ item }">
							<div class="mx-2 text-center pink--text">
								Giá đấu của KH
							</div>
							<div class="mx-2 text-center blue--text">
								{{DoiTien(item.Bid,false)}}
							</div>
							<div class="mx-2 text-center blue--text">
								{{DoiTien(item.BidVN)}}
							</div>
							<v-divider />
							<v-menu offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="blue"
										class="mx-2"
										dark
										x-small
										v-bind="attrs"
										v-on="on"
									>
										Thông tin chi tiết
									</v-btn>
								</template>
								<v-card flat>
									<v-card-text>
										<div class="mx-2"><strong>Phí mua hàng (Tạm tính)</strong></div>
										<div class="mx-2">
											{{DoiTien(item.AuctionFee,false)}} - {{DoiTien(item.AuctionFeeVN)}}
										</div>
										<v-divider />
										<div class="mx-2"><strong>Tổng (Tạm tính)</strong></div>
										<div class="mx-2">
											{{DoiTien(item.Total,false)}} - {{DoiTien(item.TotalVN)}}
										</div>
										<v-divider />
									</v-card-text>
								</v-card>
							</v-menu>
						</template>
						<template v-slot:[`item.Status`]="{ item }">
							<template v-if="Status[item.Status]">
								<v-chip
									dark
									:color="Status[item.Status]['color']"
								>
									{{Status[item.Status]['text'] ? Status[item.Status]['text']:Status[item.Status]['name']  }}
								</v-chip>
							</template>
							<template v-else>
								{{item.Status}}
							</template>
						</template>
						<template v-slot:[`item.Action`]="{ item }">
							<template v-if="item.Status === 'thanh_cong' || item.Status === 'dang_xu_ly'">
								<v-btn
									class="mx-2"
									color="green"
									dark
									@click="doCreateOrder(item)"
									small
								>
									Tạo đơn hàng
								</v-btn>
								<v-btn
									class="mx-2"
									color="red"
									dark
									@click="doCancelOrder(item)"
									small
								>
									Hủy đấu giá
								</v-btn>
							</template>

						</template>
						<template v-slot:[`item.Note`]="{ item }">
							<template v-if="Array.isArray(item.Notes) && item.Notes.length > 0">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											:color="item['Notes'][0]['Staff'] ? 'green':'orange'"
											dark
											v-bind="attrs"
											v-on="on"
											small
											@click="doGetNote(item)"
										>
											{{item['Notes'][0]['Staff'] ? 'Ghi chú từ TE':'Ghi chú từ KH'}}
										</v-btn>
									</template>
									<span>{{item['Notes'][0]['Staff'] ? 'TOM EXPRESS':'KHÁCH HÀNG'}} - Lúc: {{getCurrentTime(item['Notes'][0]['Date'])}}</span>
								</v-tooltip>
							</template>
							<template v-else>
								<v-btn
									color="blue"
									@click="doGetNote(item)"
									dark
									small
								>
									Chưa có ghi chú
								</v-btn>
							</template>
						</template>
					</v-data-table>
				</v-card>
			</v-flex>
		</v-layout>
		<v-dialog
			v-model="Box.Create"
			max-width="600px"
			persistent
		>
			<v-card flat>
				<v-card-title>
					KHỞI TẠO ĐƠN HÀNG TỪ ĐẤU GIÁ
				</v-card-title>
				<v-card-text>
					<v-text-field
						class="mx-2"
						label="Phí vận chuyển"
						v-model="newOrder.PhiShipNoiDia"
					/>
					<v-textarea
						class="mx-2"
						label="Mô tả"
						v-model="newOrder.Description"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="green"
						dark
						@click="doAddOrder()"
					>
						Tạo đơn hàng
					</v-btn>
					<v-spacer />
					<v-btn
						dark
						@click="Box.Create = false"
					>
						Hủy
					</v-btn>
				</v-card-actions>
			</v-card>
			<loading :status="Loader.Create" />
		</v-dialog>
		<v-dialog
			v-model="Box.Cancel"
			max-width="600px"
			persistent
		>
			<v-card flat>
				<v-card-title>
					HỦY ĐẤU GIÁ
				</v-card-title>
				<v-card-text>
					<v-textarea
						class="mx-2"
						label="Lí do hủy"
						v-model="newOrder.Reason"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="green"
						dark
						@click="doSubmitCancelOrder()"
					>
						Hủy đấu giá
					</v-btn>
					<v-spacer />
					<v-btn
						dark
						@click="Box.Cancel = false"
					>
						Hủy
					</v-btn>
				</v-card-actions>
			</v-card>
			<loading :status="Loader.Cancel" />
		</v-dialog>
		<v-dialog
			v-model="Box.Note"
			max-width="800px"
			persistent
		>
			<v-card>
				<v-card-title>
					THÔNG TIN GHI CHÚ: <strong>{{NoteData['Title']}}</strong>
					<v-spacer />
					<v-btn
						dark
						@click="Box.Note = false"
					>
						<v-icon>
							mdi-close
						</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-responsive
						v-if="NoteData.Notes.length > 0"
						class="overflow-y-hidden fill-height"
						height="500"
					>
						<v-list three-line>
							<template v-for="(item, index) in NoteData.Notes">
								<v-list-item
									:key="index"
								>
									<v-list-item-content>
										<v-list-item-title>
											<v-chip
												:color="item['isStaff'] ? 'green':'blue'"
												dark
												text
												small
											>
												{{item['By']}}
											</v-chip>
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item['Content']}}
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-list-item-action-text>
											<v-chip
												color="pink"
												class="text-right"
												small
												dark
											>
												{{new Date(item['Date']).toLocaleString()}}
											</v-chip>
										</v-list-item-action-text>
										<br />
										<v-list-item-action-text>
											<v-chip
												:color="item['isStaff'] ? 'green':'blue'"
												x-small
												dark
											>
												{{item['isStaff'] ? 'TOMEXPRESS':'KHÁCH HÀNG'}}
											</v-chip>
										</v-list-item-action-text>
									</v-list-item-action>
								</v-list-item>
								<v-divider />
							</template>
						</v-list>
					</v-responsive>
					<template v-else>
						{{ Loader.Note ? 'Đang tải nội dung':'Chưa có nội dung'}}
					</template>
				</v-card-text>
				<v-card-text class="flex-shrink-1">
					<v-text-field
						v-model="NewNoteContent"
						label="Nội dung"
						type="text"
						no-details
						outlined
						append-outer-icon="mdi-send"
						@keyup.enter="doAddNote()"
						@click:append-outer="doAddNote()"
						hide-details
					/>
				</v-card-text>
			</v-card>
			<loading :status="Loader.Note" />
		</v-dialog>
		<loading :status="Loader.Data" />
	</v-container>
</template>

<script>
import loading from '@/components/base/Loading.vue';
import Countdown from '@/components/Countdown.vue';
import {toArray, serialize} from '@/plugins/helper/object';
import qPartner from '@/plugins/query/partner';
import AuctionsStatus from '@/json/auctions.json';
import { Status } from '@/plugins/helper/dstatus';
import {DoiCuoc} from '@/plugins/helper/tinhtien';
import mm from 'moment-timezone';
import {mapGetters} from "vuex";
import moment from "moment-timezone";
export default {
	name: "YahooAuctions",
	components:{
		loading,
		Countdown,
	},
	data(){
		return {
			NoteData:{
				ID: null,
				Title:null,
				Notes:[]
			},
			NewNoteContent: null,
			Search: null,
			totalItems: 0,
			items: [],
			newOrder:{},
			Box:{
				Create: false,
				Cancel: false,
				Note: false,
			},
			Filter:{
				Status: 'dang_dau_gia',
				Search: null,
			},
			Loader:{
				Data: false,
				Create: false,
				Cancel: false,
				Note: false,
			},
			options:{},
			timeOut: null,
		}
	},
	computed:{
		...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		notice(){ return new Status(this.$swal)},
		Status(){
			return AuctionsStatus;
		},
		StatusList(){
			return toArray(this.Status);
		},
		headers(){
			let headers = [
				{ text: 'Mã sản phẩm', value: 'Product', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Mã giao dịch', value: 'Order', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Mô tả', value: 'Description', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Hình ảnh', value: 'Image', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Tên sản phẩm', value: 'Name', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Khách hàng', value: 'Customer.Username', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Sale', value: 'Staff.Username', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Tài khoản YA', value: 'Account.Account', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Thông tin', value: 'Price', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Giá khách đấu', value: 'Bid', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Trạng thái', value: 'Status', align: 'center',width: "100px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Ghi chú', value: 'Note', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Kết thúc', value: 'End', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Thao tác', value: 'Action', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
			];
			if(this.loginInfo._perm !== 'admin'){
				return headers.filter(item=>item.value !== 'Staff.Username');
			}
			return headers;
		},
	},
	watch: {
		Filter: {
			handler () {
				this.doFetch();
			},
			deep: true,
		},
		options: {
			handler () {
				this.doFetch();
			},
			deep: true,
		},
	},
	methods:{
		...qPartner,
		DoiTien:DoiCuoc,
		getCurrentTime(time){
			const local = moment.tz.guess();
			return moment(time).tz(local).format("DD-MM-YYYY HH:mm");
		},
		doLoadNote(){
			const {NoteData} = this;
			if(NoteData.ID){
				const app = this;
				app.Loader.Note = true;
				app.qrGetNote(NoteData.ID,true).then(response =>{
					return response.json();
				}).then(json=>{
					app.NoteData['Notes'] = json.data;
				}).catch(e=>{
					console.error(e);
					app.notice.error('Không thể tải thông tin ghi chú !!!');
					app.boxNote = false;
				}).finally(()=>{
					app.Loader.Note = false;
				})
			}
		},
		doGetNote(item){
			this.NoteData = {
				ID: item._id,
				Title: `${item.Product}-${item.Order}`,
				Notes: [],
			};
			this.Box.Note = true;
			this.doLoadNote();
		},
		doAddNote(){
			const {NewNoteContent, NoteData} = this;
			if(NewNoteContent){
				const request = {
					note: NewNoteContent
				};
				const app = this;
				app.Loader.Note = true;
				this.qrAddNote(NoteData.ID,request,true).then(response=>{
					return response.json();
				})
					.then(json=>{
						const {success, error} = json;
						if(error){
							app.notice.error(error);
						}
						if(success){
							app['NewNoteContent'] = null;
							setTimeout(app.doLoadNote(),100);
						}
					})
					.catch(e=>{
						console.error(e);
						app.notice.error('Không thể thêm ghi chú !!!');
					})
					.finally(()=>{
						app.Loader.Note = false;
					})
			}else{
				this.notice.error('Không có nội dung ghi chú !!!');
			}
		},
		doCancelOrder(item){
			this.newOrder = {
				ID: item._id,
				Reason: '',
			};
			this.Box.Cancel = true;
		},
		doCreateOrder(item){
			this.newOrder = {
				ID: item._id,
				PhiShipNoiDia: 0,
				Description: item.Description,
			};
			this.Box.Create = true;
		},
		doSubmitCancelOrder(){
			const {newOrder} = this;
			if(!newOrder.ID){
				this.notice.error('Chọn phiên đấu giá !!!');
				return;
			}
			if(newOrder.Reason.length < 1){
				this.notice.error('Phải nhập lí do hủy !!!');
				return;
			}
			this.Loader.Cancel = true;
			this.qrCancelAuction(newOrder.ID, newOrder.Reason).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(error){
					this.notice.error(error);
				}
				if(success) {
					this.notice.success('Đã hủy phiên đấu giá thành công !!!');
					this.Box.Cancel = false;
					this.doFetch();
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error('Đã có lỗi xảy ra !!!');
			}).finally(()=>{
				this.Loader.Cancel = false;
			});
		},
		doAddOrder(){
			const {newOrder} = this;
			let PhiShipNoiDia = parseInt(newOrder.PhiShipNoiDia);
			if(!newOrder.ID){
				this.notice.error('Chọn phiên đấu giá !!!');
				return;
			}
			if(isNaN(PhiShipNoiDia) || PhiShipNoiDia < 0){
				this.notice.error('Nhập phí ship nội địa !!!');
				return;
			}
			if(newOrder.Description.length < 1){
				this.notice.error('Nội dung mô tả phải nhập !!!');
				return;
			}
			this.Loader.Create = true;
			this.qrCreateAuction(newOrder.ID, {PhiShipNoiDia: PhiShipNoiDia, Description: newOrder.Description}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(error){
					this.notice.error(error);
				}
				if(success) {
					this.notice.success('Đã tạo đơn hàng thành công !!!');
					this.Box.Create = false;
					this.doFetch();
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error('Đã có lỗi xảy ra !!!');
			}).finally(()=>{
				this.Loader.Create = false;
			});
		},
		convertTime(time){
			return mm(time).format('YYYY-MM-DD HH:mm:ss');
		},
		doFetch(){
			let Query = Object.assign({},this.Filter);
			const {page, itemsPerPage} = this.options;
			Query['page'] = page;
			Query['itemsPerPage'] = itemsPerPage;
			return this.qrAuctions(serialize(Query)).then(resp=>{
				return resp.json();
			}).then(json=> {
				if (json.data) this.items = json.data;
				if (json.total) this.totalItems = json.total;
			}).catch(e=>{
				console.error(e);
				this.notice.error(e.message);
			}).finally(()=>{
				this.Loader.Data = false;
			});
		},
		onSearch(){
			this.Filter.Search = this.Search;
		},
		doSearch(){
			clearTimeout(this.timeOut);
			const app = this;
			this.timeOut = setTimeout(() => {
				app.Filter.Search = app.Search;
			}, 100);
		},
	},
	mounted(){
		this.doFetch();
	},
}
</script>

<style scoped>

</style>