import { Request } from '@/plugins/helper/fetch'
const req = new Request('partner');
const qrDelete = (id) => {
  if(Array.isArray(id) && id.length > 0){
    return req.patch(`delete`,{items:id});
  }
  if(id){
    return req.delete(`${id}`);
  }
}
const qrMerge = ({Items, Shipment, Code, D, R, C, GW, NW, Type}) =>{
  return req.put('merge',{Items, Shipment, Code, D, R, C, GW, NW, Type}).then(response =>{
    if(response.headers.get('Content-Type') === 'application/pdf'){
      return response.blob();
    }
    return response.json();
  });
}
const qrPDF = ({Package, Code, Customer, KL, TT, Shipping, Sale}) =>{
  return req.post('label',{Package, Code, Customer, KL, TT, Shipping, Sale}).then(response =>{
    if(response.headers.get('Content-Type') === 'application/pdf'){
      return response.blob();
    }
    return response.json();
  });
}
const qrConfirm = (id) => {
  return req.patch(`confirm-order/${id}`, {confirmed: true}).then(response =>response.json());
}
const qrUpdateAddress = (id,query) => {
  return req.patch(`address/${id}`,query).then(response =>response.json());
}
const qrGetAddressList = (id) => {
    return req.get(`fetch/address/${id}`)
}
const qrGetNote = (id,auction=false) =>{
    let uri = `fetch/note/${id}`;
    if(auction){
      uri = `fetch/note/auction/${id}`;
    }
    return req.get(uri)
}
const qrAddNote = (id, query,auction=false) =>{
  let uri = `update/note/${id}`;
  if(auction){
    uri = `update/note/auction/${id}`;
  }
  return req.put(uri,query);
}
const qrExportExcel = (query)=>{
  if(!query) query = '';
  else query = '?'+query;
  return req.get('export-excel'+query);
}
const qrSaveKL = (id, KL) =>{
  return req.patch('update/kl/'+id, {KL});
}
const qrSavePhiShip = (id, PhiShipNoiDia) =>{
  return req.patch('update/phi-ship/'+id, {PhiShipNoiDia});
}
const qrSavePhi = (id, Phi) =>{
  return req.patch('update/phi/'+id, {Phi});
}
const qrSaveDRC = (id, {D, R, C}) =>{
  return req.patch('update/drc/'+id, {D, R, C});
}
const qrSaveShipping = (id, Shipping) =>{
  return req.patch('update/shipping/'+id, {Shipping});
}
const qrSaveStatus = (id, Status) =>{
  return req.patch('update/status/'+id, {Status});
}
const qrSavePaid = (id, Paid) =>{
  return req.patch('update/paid/'+id, {Paid});
}
const qrSavePurchased = (id, Purchased) =>{
  return req.patch('update/purchased/'+id, {Purchased});
}
const qrFindTrack = (Tracking) =>{
  return req.search('find-track', {Tracking});
}
const qrAddTrack = (Tracking, Data) =>{
  return req.put('wait-list', {Tracking, ...Data});
}
const qrImportTrack = (Tracking, Data) =>{
  return req.patch('import-track', {Data, Tracking});
}
const qrExportTrack = (Tracking) =>{
  return req.patch('export-track', {Tracking});
}
const qrPendingTrack = () =>{
  return req.fetch('wait-list');
}
const qrRemoveTrack = (id) =>{
  return req.remove('/wait-list/'+id);
}
const qrUploadImage = (id,file,onUploadProgress) =>{
  return req.Upload(id,file,onUploadProgress);
}
const qrAuctions = (Query) =>{
  return req.get('fetch-auctions?'+Query);
}
const qrCreateAuction = (ID, Data) =>{
  return req.put('auctions/create/'+ID, Data);
}
const qrCancelAuction = (ID, Reason) =>{
  return req.patch('auctions/cancel/'+ID, {Reason: Reason});
}
const qrYaTracking = (ID) =>{
  return req.get('ya-tracking/'+ID);
}
const qrUpdateTracking = (ID,Tracking) =>{
  return req.patch('updateTrack/'+ID, {Tracking});
}
export default {
  qrGetNote,
  qrAddNote,
  qrSaveKL,
  qrSaveDRC,
  qrSavePhi,
  qrSaveShipping,
  qrSaveStatus,
  qrSavePurchased,
  qrSavePaid,
  qrFindTrack,
  qrAddTrack,
  qrImportTrack,
  qrExportTrack,
  qrPendingTrack,
  qrRemoveTrack,
  qrUploadImage,
  qrExportExcel,
  qrSavePhiShip,
  qrGetAddressList,
  qrUpdateAddress,
  qrPDF,
  qrMerge,
  qrDelete,
  qrAuctions,
  qrCreateAuction,
  qrCancelAuction,
  qrConfirm,
  qrYaTracking,
  qrUpdateTracking
}